<template>
    <div class="comments-widget">
        <div :class="{ blur: deletableComment }">
            <default-title v-if="comments.length" class="mb-5" :size="16" :line-height="21" :weight="500">
                {{ t("course.practice.comments") }}
            </default-title>

            <DefaultCheck v-if="!isPublic()" class="mb-20" @update="updateCommentAllowed()" v-model="isCommentAllowed">
                {{ t("quiz.settings.attempt_comment") }}
            </DefaultCheck>

            <LoaderBlock v-if="loaders.all" :green-color="true" />
            <template v-else>
                <div ref="wrapper" class="comments-wrapper custom-scroll">
                    <div
                        class="comments-widget-item"
                        :ref="'comment_' + comment.id"
                        :key="comment.id"
                        :class="{ active: id && comment.id === id }"
                        v-for="comment in comments"
                    >
                        <flex-container>
                            <CommentAuthor :author="comment.user" class="mr-12 mt-5" />
                            <flex-container class="flex-1" justify="space-between">
                                <div>
                                    <default-title small black :font-size="13">
                                        {{ comment.user.name }}
                                        <span
                                            :style="{ pointerEvents: canBeChanged(comment.user) ? 'all' : 'none' }"
                                            style="display: inline-block; position: relative"
                                            v-if="!isPublic()"
                                            :data-tooltip="
                                                t(
                                                    comment.is_private
                                                        ? 'course.practice.comment_unavailable_student'
                                                        : 'course.practice.comment_available_student'
                                                )
                                            "
                                            @click.stop.prevent="changeVisibility($event, comment)"
                                            @mouseleave="isHover($event, false)"
                                            @mouseover="
                                                isHover($event, true, {
                                                    center: true,
                                                    move_up: 17
                                                })
                                            "
                                        >
                                            <EyeStrokedIcon
                                                fill="#D14141"
                                                hovered
                                                style="margin-bottom: -2px"
                                                v-if="comment.is_private"
                                                width="14"
                                                height="14"
                                            />
                                            <EyeIcon
                                                fill="#3965FF"
                                                hovered
                                                style="margin-bottom: -2px"
                                                v-else
                                                width="14"
                                                height="14"
                                            />
                                        </span>
                                    </default-title>
                                    <default-title small :font-size="10">
                                        {{ formatdate(comment.created_at, "DD MMMM YYYY, HH:mm") }}
                                        <template v-if="comment.updated_at !== comment.created_at"
                                            >({{ t("quiz.edited_comment") }}
                                            {{ comment.updated_at | formatDateWithTimeNoSeconds2 }})</template
                                        >
                                    </default-title>

                                    <default-title
                                        small
                                        :font-size="10"
                                        v-if="
                                            comment.commentable &&
                                            comment.commentable.quiz_attempt &&
                                            comment.commentable.quiz_attempt.finished_at
                                        "
                                        textColor="#98A2B0 !important"
                                    >
                                        {{ t("quiz.version_attempt_at") }}
                                        {{
                                            formatdate(
                                                comment.commentable.quiz_attempt.finished_at,
                                                "DD MMMM YYYY, HH:mm"
                                            )
                                        }}
                                    </default-title>
                                </div>
                                <flex-container align="center" v-if="!isPublic()" class="ml-10">
                                    <template v-if="userId == comment.user_id">
                                        <button
                                            class="h-20 w-20 mr-8"
                                            v-if="!(id && comment.id === id)"
                                            @click.stop="editComment(comment)"
                                        >
                                            <EditIcon height="18" width="18" hoverable />
                                        </button>

                                        <CloseButtonSm class="mr-8 relative" @close="clear" v-else />
                                    </template>

                                    <button @click="deletableComment = comment" class="h-20 w-20 flex items-center">
                                        <DeleteIcon class="hovered-red" height="18" width="18" hoverable />
                                    </button>
                                </flex-container>
                            </flex-container>
                        </flex-container>
                        <default-title
                            :font-size="14"
                            small
                            black
                            class="mt-8 mr-10"
                            style="font-style: italic; word-break: break-word"
                        >
                            <div v-html="getHtml(comment.text)"></div>
                        </default-title>
                    </div>
                </div>
                <div class="relative mt-15" v-if="canComment">
                    <LoaderBlock class="comment-loader" v-if="loaders.comment" :green-color="true" />

                    <textarea
                        :class="{ disabled: loaders.comment }"
                        :id="`area${commentable_id}`"
                        v-model="text"
                        @keydown.enter.prevent.exact="submit"
                        @keydown.ctrl.enter.prevent="newLine"
                        @keydown.alt.enter.prevent="newLine"
                        @keydown.shift.enter.prevent="newLine"
                        ref="area"
                        :placeholder="t('course.practice.leave_comment')"
                        class="comments-widget-textarea custom-scroll"
                    ></textarea>
                </div>
                <flex-container
                    v-if="!isPublic()"
                    @click.native="available = !available"
                    class="mt-10 cursor-pointer"
                    align="center"
                >
                    <PracticeCheck :active="available" />
                    <default-description style="margin-top: 4px" class="black" :weight="400">
                        {{ t("course.practice.comment_available") }}
                    </default-description>
                </flex-container>
                <flex-container class="mt-20" v-if="canComment">
                    <DefaultButton
                        class="mr-10"
                        :width="190"
                        :height="45"
                        @click="$emit('close')"
                        background="cancel"
                        :text="t('expert.cancel')"
                    />
                    <DefaultButton
                        :width="190"
                        :height="45"
                        :disabled="!text || loaders.comment"
                        @click="submit()"
                        background="green"
                        :text="t(id ? 'contacts.change' : 'create-course.send')"
                    />
                </flex-container>
            </template>
        </div>
        <DeleteModal
            v-if="deletableComment"
            :loading="deleteLoader"
            @cancel="deletableComment = null"
            @delete="deleteComment"
            class="absolute top-100"
            :title="t('quiz.delete_comment')"
        />
    </div>
</template>

<script>
import CloseButtonSm from "@components/Buttons/CloseButtonSm.vue"
import DefaultCheck from "@components/Forms/DefaultCheck.vue"
import DefaultTitle from "@components/Typography/DefaultTitle.vue"
import DeleteModal from "@expert-components/Modals/DeleteModal.vue"
import { handleError } from "@helpers"
import DeleteIcon from "@icons/DeleteIcon.vue"
import EditIcon from "@icons/EditIcon.vue"
import EyeIcon from "@icons/EyeIcon.vue"
import EyeStrokedIcon from "@icons/EyeStrokedIcon.vue"
import axios from "~axios"
import tooltipOwn from "@mixins/tooltipOwn"
import moment from "moment-timezone"
import DefaultButton from "../../../Buttons/DefaultButton.vue"
import FlexContainer from "../../../Containers/FlexContainer.vue"
import LoaderBlock from "../../../Loaders/LoaderBlock.vue"
import PracticeCheck from "../inputs/PracticeCheck.vue"
import DefaultDescription from "../../../Typography/DefaultDescription.vue"
import CommentAuthor from "../../../Comments/CommentAuthor.vue"

import "moment/dist/locale/ru"
import "moment/dist/locale/uk"
import "moment/dist/locale/it"
import "moment/dist/locale/es"
import "moment/dist/locale/de"
import "moment/dist/locale/pl"
import "moment/dist/locale/fr"
import "moment/dist/locale/lv"
import "moment/dist/locale/pt"
import "moment/dist/locale/cs"
import "moment/dist/locale/ka"
import "moment/dist/locale/el"
import "moment/dist/locale/ro"
import "moment/dist/locale/zh-cn"

moment.locale(window.app_current_locale)

export default {
    components: {
        DeleteModal,
        EditIcon,
        DeleteIcon,
        CloseButtonSm,
        DefaultCheck,
        DefaultTitle,
        DefaultButton,
        EyeStrokedIcon,
        EyeIcon,
        PracticeCheck,
        LoaderBlock,
        DefaultDescription,
        FlexContainer,
        CommentAuthor
    },
    mixins: [tooltipOwn],
    props: {
        commentable_type: {
            type: String,
            default: "question_id"
        },
        commentable_id: {
            type: Number,
            default: 0
        },
        attempt_id: {
            type: Number,
            default: 0
        },
        public: {
            type: Boolean,
            default: false
        }
    },
    name: "AttemptCommentsWidget",
    data() {
        return {
            comments: [],
            text: "",
            id: null,
            available: true,
            loaders: {
                all: false,
                comment: false
            },
            deletableComment: null,
            deleteLoader: false,
            isCommentAllowed: false
        }
    },
    created() {
        this.getComments()
    },
    computed: {
        userId() {
            return window.user_id
        },
        canComment() {
            return !this.public || this.isCommentAllowed
        }
    },
    methods: {
        clear() {
            this.id = null
            this.text = ""
        },
        async deleteComment() {
            this.deleteLoader = true

            const id = this.deletableComment.id

            try {
                await axios.delete(`comments/${id}`)
                this.comments = this.comments.filter(c => c.id !== id)
            } catch (e) {
                handleError(e)
            } finally {
                this.$forceUpdate()
                this.deletableComment = null
                this.deleteLoader = false
            }
        },
        editComment(comment) {
            const { text_formatted, id } = comment

            this.text = text_formatted?.without_tags
            this.id = id
        },
        updateCommentAllowed() {
            axios.post(`/quiz-attempts/${this.attempt_id}/quiz-result-comment-settings`, {
                result_commentable_type: this.commentable_type,
                result_commentable_id: this.commentable_id,
                is_allowed: this.isCommentAllowed
            })
        },
        getHtml(text) {
            return new DOMParser().parseFromString(text, "text/html").documentElement.textContent
        },
        canBeChanged(user) {
            return (
                this.$store &&
                this.$store.getters["user/currentUser"] &&
                this.$store.getters["user/currentUser"].id === user.id
            )
        },
        isPublic() {
            return this.public
        },
        formatdate(value, format = "MMMM DD, YYYY") {
            return moment(value).format(format)
        },
        async submit() {
            if (!this.text) {
                return
            }

            this.loaders.comment = true

            const params = {
                commentable_type: "quiz-attempt",
                commentable_id: this.attempt_id,
                text: this.text,
                is_private: !this.available,
                [this.commentable_type]: this.commentable_id
            }

            let url = this.public ? `/public/comments` : "/comments"

            if (this.id) {
                url = `/comments/${this.id}`
            }

            const method = this.id ? "put" : "post"

            const { data } = await axios[method](url, params)
            this.loaders.comment = false
            if (!this.id) {
                this.comments.push(data.data)
            } else {
                const item = this.comments.find(c => c.id === this.id)

                if (item) {
                    const index = this.comments.indexOf(item)
                    this.comments[index] = data.data
                }
            }
            this.$emit("add")
            this.clear()
            this.$nextTick(() => {
                if (this.$refs.wrapper) {
                    this.$refs.wrapper.scrollTop = this.$refs.wrapper.scrollHeight
                }
            })
        },
        newLine(e) {
            document.execCommand("insertLineBreak")
            e.preventDefault()
        },
        changeVisibility(e, comment) {
            axios.post(`/comments/${comment.id}/${comment.is_private ? "public" : "private"}`)
            comment.is_private = !comment.is_private
            this.$forceUpdate()
            this.isHover(e, false)
        },
        async getComments() {
            this.loaders.all = true
            try {
                setTimeout(async () => {
                    let url = `${this.public ? "/public" : ""}/comments/quiz-attempt/${this.attempt_id}`

                    url += `?${this.commentable_type}=${this.commentable_id}`

                    const { data } = await axios.get(url)

                    if (!this.public) {
                        this.comments = data.comments.data
                        this.isCommentAllowed = data.comments.is_user_comment_allowed
                    } else {
                        this.comments = data.data
                        this.isCommentAllowed = data.is_user_comment_allowed
                    }

                    this.loaders.all = false
                    this.$nextTick(() => {
                        let params = new URLSearchParams(window.location.search)

                        let commentId = params.get("comment_id")

                        if (commentId && this.$refs[`comment_${commentId}`].length) {
                            this.$refs[`comment_${commentId}`][0].scrollIntoView({
                                behavior: "smooth",
                                block: "center"
                            })

                            if (this.$route) {
                                let query = Object.assign({}, this.$route.query)
                                delete query.comment_id

                                this.$router.replace({ query })
                            } else {
                                const url = new URL(window.location)

                                url.searchParams.delete("comment_id")

                                window.history.replaceState({}, "", url)
                            }
                        } else {
                            if (this.$refs.wrapper) {
                                this.$refs.wrapper.scrollTop = this.$refs.wrapper.scrollHeight
                            }
                        }
                    })
                }, 300)
            } catch (e) {
                console.log(e)
            }
        }
    }
}
</script>

<style scoped lang="sass">
.comments-widget
    position: relative
    .delete-modal
        transform: translateX(-50%)
        left: 50%
    .blur
        filter: blur(1px)
    width: 100%
    .comments-widget-textarea
        border: 1px solid #E0E0EC
        border-radius: 8px
        width: 100%
        min-height: 130px
        padding: 14px 32px
        font-size: 13px
        font-family: 'Lato', sans-serif
        font-weight: 500
        line-height: 17px
        &::placeholder
            color: #B5C1D2
            font-weight: 500
    .comments-widget-item
        background-color: #fff
        border-radius: 10px
        padding: 10px
        &.active
            background-color: #ecf3ff
    .comments-wrapper
        max-height: 290px
        overflow-y: auto
    .comments-widget-label
        position: absolute
        font-weight: 600
        font-size: 10px
        line-height: 13px
        color: #B5C1D2
        font-family: Lato, sans-serif
        padding: 0 4px
        background: #fff
        top: -5px
        left: 28px
        z-index: 4
        &.load
            color: rgba(#B5C1D2, 0.4)
    .comment-loader
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)
        z-index: 5
</style>
